import React from "react"
import HomePageLayout from "../../components/layouts/HomePageLayout"
import generalClasses from "./general.module.css"
import SEO from "../../components/seo"

import websiteImage from "../../images/case-studies/develop-ondo.jpg"
import Footer from "../../components/footer"

import { Link } from "gatsby"
import arrowLeft from "../../images/arrow-left.svg"
import LeftIcon from "../../images/arrow-left.inline.svg"

const DevelopOndo = props => (
  <HomePageLayout pageTitle="DEVELOP ONDO" background>
    <SEO title="Develop Ondo Case Study" />
    <main className={generalClasses.main}>
      <Link to={"/solutions/projects"} className={generalClasses.backLink}>
        <img src={arrowLeft} alt="" className={generalClasses.backLinkImage} />
        Projects
      </Link>
      <section
        className={[generalClasses.caseStudyIntro, generalClasses.section].join(
          " "
        )}
      >
        <div>
          <h1 className={generalClasses.caseStudyHeader}>DEVELOP ONDO</h1>
          <p className={generalClasses.caseStudyDescription}>
            Prunedge designed and developed a website for a two-day conference
            in Ondo state.
          </p>
          <p className={generalClasses.caseStudyCategory}>
            UI / UX | WEB DEVELOPMENT
          </p>
          <a
            href="https://developondo.com/"
            target="_blank"
            rel="noopener noreferrer"
            className={generalClasses.caseStudyLink}
          >
            VISIT WEBSITE
          </a>
        </div>
        <ul className={generalClasses.caseStudyDetails}>
          <li className={generalClasses.caseStudyDetail}>
            <h4 className={generalClasses.caseStudyDetailHeader}>CLIENT</h4>
            <p className={generalClasses.caseStudyDetailContent}>
              Ondo State government
            </p>
          </li>
          <li className={generalClasses.caseStudyDetail}>
            <h4 className={generalClasses.caseStudyDetailHeader}>LOCATION</h4>
            <p className={generalClasses.caseStudyDetailContent}>
              Ondo, Nigeria
            </p>
          </li>
          <li className={generalClasses.caseStudyDetail}>
            <h4 className={generalClasses.caseStudyDetailHeader}>USERS</h4>
            <p className={generalClasses.caseStudyDetailContent}>
              1 - 1000 Users
            </p>
          </li>
          <li className={generalClasses.caseStudyDetail}>
            <h4 className={generalClasses.caseStudyDetailHeader}>INDUSTRY</h4>
            <p className={generalClasses.caseStudyDetailContent}>Government</p>
          </li>
        </ul>
      </section>
      <section className={generalClasses.section}>
        <h1 className={generalClasses.meetCaseStudyHeader}>
          MEET DEVELOP ONDO
        </h1>
        <p className={generalClasses.meetCaseStudyText}>
          Develop ondo was a two-day conference that aimed at engaging
          developmental partners from different sectors for the advancement of
          Ondo State.
        </p>
      </section>
      <section
        className={[generalClasses.grid, generalClasses.section].join(" ")}
      >
        <div>
          <h1 className={generalClasses.sectionHeader}>our idea</h1>
          <p className={generalClasses.sectionText}>
            We decided to come up with a website that suited the Ondo State
            Government and also intuitive enough for users to understand how to
            navigate through the website easily.
          </p>
        </div>
        <div>
          <h1 className={generalClasses.sectionHeader}>Result</h1>
          <p className={generalClasses.sectionText}>
            An intuitively crafted website was delivered to the Ondo State
            Government which provides users with informations about the state
            and details about the MDAs in the state.
          </p>
        </div>
      </section>
      <img
        src={websiteImage}
        alt=""
        className={generalClasses.displayImageFull}
      />
      <div className={generalClasses.caseStudyPageLinks}>
        <Link
          to="/case-studies/ondo-state-website"
          className={generalClasses.caseStudyPageLink}
        >
          <span className={generalClasses.caseStudyPageLinkIcon}>
            <LeftIcon
              className={generalClasses.caseStudyPageLinkIconImage}
              width="11px"
              height="18px"
            />
          </span>
          <p className={generalClasses.caseStudyPageLinkText}>
            <span className={generalClasses.caseStudyPageLinkTag}>
              PREVIOUS
            </span>
            <span className={generalClasses.caseStudyPageLinkTitle}>
              Ondo State Website
            </span>
          </p>
        </Link>
      </div>
    </main>
    <Footer />
  </HomePageLayout>
)

export default DevelopOndo
